.collection-overview {
  margin-bottom: 50px;
  overflow-x: hidden;
  padding-top: 40px;
}
@media screen and (max-width: 800px) {
  .collection-overview {
    margin-bottom: 30px;
 }
}
