.collection-item {
  position: relative;
  display: inline-block;
}
.collection-item:hover .collection-item__text {
  visibility: visible;
  transition: opacity 0.62s, ease 1s;
  opacity: 1;
}
.collection-item:hover .collection-item__hour {
  visibility: hidden;
}
.collection-item:hover .collection-item__addtolist {
  visibility: visible;
  transition: opacity 0.62s, ease 1s;
  opacity: 1;
}
.collection-item:hover .collection-item__movie-image {
  transition: opacity 0.62s, ease 1s;
  opacity: 0.2;
}
.collection-item__movie-image {
  width: 258px;
  z-index: 1;
}
@media screen and (max-width: 800px) {
  .collection-item__movie-image {
    width: 230px;
 }
}
.collection-item__movie-video {
  width: 258px;
  z-index: 1;
}
@media screen and (max-width: 800px) {
  .collection-item__movie-video {
    width: 230px;
 }
}
.collection-item__text {
  position: absolute;
  bottom: 36%;
  left: 2%;
  right: 1%;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.62s, ease 1s;
  font-size: 10px;
  text-shadow: 3px 3px 4px black;
}
@media screen and (max-width: 800px) {
  .collection-item__text {
    display: none;
 }
}
.collection-item__title {
  color: white;
  font-size: 0.9rem;
}
@media screen and (max-width: 800px) {
  .collection-item__title {
    font-size: 0.6rem;
 }
}
.collection-item__overview {
  color: white;
}
@media screen and (max-width: 800px) {
  .collection-item__overview {
    font-size: 0.6rem;
 }
}
.collection-item__addtolist {
  position: absolute;
  bottom: 18%;
  left: 1.7%;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 10px;
  text-shadow: 3px 3px 4px black;
  transition: opacity 0.62s, ease 1s;
}
@media screen and (max-width: 800px) {
  .collection-item__addtolist {
    display: none;
 }
}
.collection-item__hour {
  position: absolute;
  bottom: -3%;
  left: 1%;
  right: 1%;
  color: #fff;
  font-size: 20px;
  text-shadow: 3px 3px 4px black;
}
@media screen and (max-width: 800px) {
  .collection-item__hour {
    display: none;
 }
}
