.title {
  color: #633d8c;
}
.color-paragraph {
  color: #fefefe;
  text-align: justify;
}
.two-paragraph {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
