@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .border-top-color {
    border-top-color: #636767;
  }
}

.App {
  text-align: center;
}

body {
  overflow: auto !important;
}

.full-screen-widget {
  display: none;
}
