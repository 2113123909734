.ekg-svg {
  max-width: 470px;
  margin-left: 20px;
  margin-right: 20px;
  position: fixed;
  transition: all 0.5s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ekg {
  fill: none;
  stroke: #633d8c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: miter;
  opacity: 0;
  stroke-dashoffset: 1000;
  stroke-dasharray: 1000;
  animation: ekg 1.5s linear forwards infinite;
}
@keyframes ekg {
  0% {
    opacity: 0;
 }
  25% {
    opacity: 1;
 }
  50% {
    stroke-dashoffset: 2000;
 }
  99% {
    opacity: 0;
    stroke-dashoffset: 3000;
 }
  100% {
    stroke-dashoffset: 1000;
 }
}
