.collection-preview {
  display: flex;
  flex-direction: column;
  height: 190px;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .collection-preview {
    height: 210px;
 }
}
.collection-preview__title {
  font-size: 1.4rem;
  padding-left: 80px;
}
@media screen and (max-width: 800px) {
  .collection-preview__title {
    font-size: 1rem;
 }
}
.collection-preview__collection-item {
  display: flex;
  height: 100%;
  flex-direction: row;
  position: relative;
}
.collection-preview__left-arrow, .collection-preview__right-arrow {
  height: 168.4px;
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  z-index: 1;
  opacity: 1;
  color: white;
}
@media screen and (max-width: 800px) {
  .collection-preview__left-arrow, .collection-preview__right-arrow {
    height: 129.44px;
    -webkit-visibility: hidden;
 }
}
.collection-preview__left-arrow {
  position: absolute;
  left: 5px;
}
.collection-preview__right-arrow {
  position: absolute;
  right: 5px;
  right: 0%;
}
.collection-preview__left-arrow-icon, .collection-preview__right-arrow-icon {
  cursor: pointer;
}
.collection-preview__left-arrow-icon:hover, .collection-preview__right-arrow-icon:hover {
  font-size: 3rem;
  color: white;
  transition: all 0.2s ease;
}
.collection-preview__inner {
  display: flex;
  cursor: pointer;
  height: 168.4px;
}
@media screen and (max-width: 800px) {
  .collection-preview__inner {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
 }
  .collection-preview__inner::-webkit-scrollbar {
    display: none;
 }
}
@media screen and (min-width: 800px) {
  .collection-preview__inner:hover .collection-item {
    transform: translateX(-25%);
 }
  .collection-preview__inner .collection-item:hover ~ .collection-item {
    transform: translateX(25%);
 }
  .collection-preview__inner .collection-item:first-child:hover ~ .collection-item {
    transform: translateX(50%);
 }
  .collection-preview__inner .collection-item:last-child:hover ~ .collection-item {
    transform: translateX(-50%);
 }
  .collection-preview__inner .collection-item {
    transition: transform 300ms ease 100ms;
 }
  .collection-preview__inner .collection-item:first-child {
    transform-origin: left;
 }
  .collection-preview__inner .collection-item:last-child {
    transform-origin: right;
 }
  .collection-preview__inner .collection-item:hover {
    transform: scale(1.5);
 }
  .collection-preview__inner .collection-item:hover:first-child {
    transform: scale(1.5);
 }
  .collection-preview__inner .collection-item:hover:last-child {
    transform: scale(1.25);
 }
}
